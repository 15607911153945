import Map from '../Map/Map';
import styles from "./LogisticsScreen.module.css";

const LogisticScreen = () => {
    return (
        <div className={styles.container}>
            <Map/>
        </div>
    );
}

export default LogisticScreen;